import './AppointmentEditSubjects.less'

import { InputNumber } from 'antd'
import { FormInstance } from 'antd/lib/form'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { SorterOrder, fetchSubjectsInRepository } from '../../../../../requests'
import { getInitials } from '../../../../../utils'
import { isLessThan, validateRequired } from '../../../../../validation'
import {
  DatacFormItem,
  DatacIcon,
  DatacInformationMessage,
  DatacMessage,
  DatacOption,
  DatacSelect
} from '../../../../common'
import { useCalendarStore } from '../../../CalendarStore'

export const defaultCapacity = 1

interface AppointmentEditSubjectsProps {
  isVisible: boolean
  formInstance: FormInstance
  currentSubjects: DatacOption[]
  setCurrentSubjects: (subjects: DatacOption[]) => void
}

export const AppointmentEditSubjects: React.VFC<AppointmentEditSubjectsProps> = ({
  isVisible,
  formInstance,
  currentSubjects,
  setCurrentSubjects
}) => {
  const intlAppointment = useScopedIntl('calendar.appointment')
  const intl = useScopedIntl('')
  const [subjectOptions, setSubjectOptions] = useState<DatacOption[]>([])
  const [isFetchingSubjectList, setIsFetchingSubjectList] = useState(false)
  const [currentCapacity, setCurrentCapacity] = useState(defaultCapacity)
  const [error, setError] = useState<string>()
  const { appointmentToEdit } = useCalendarStore()

  useEffect(() => {
    if (!isVisible) return
    setSubjectOptions([])
    setCurrentCapacity(appointmentToEdit?.capacity || defaultCapacity)
    setError(undefined)
  }, [isVisible])

  const onSearchSubjects = debounce((search = '') => {
    setIsFetchingSubjectList(true)
    fetchSubjectsInRepository(
      {
        search,
        limit: 25,
        offset: 0,
        sorter: { order: SorterOrder.Descend, field: 'firstName' },
        conditions: []
      },
      {
        onSuccess: ({ subjects }) => {
          setSubjectOptions(
            subjects
              .map(({ id, firstName, lastName, email }) => ({
                value: id,
                label: `${firstName || ''} ${lastName || ''} (${id})`,
                sublabel: email
              }))
              .filter(s => !currentSubjects.find(({ value }) => value === s.value))
          )
          setIsFetchingSubjectList(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingSubjectList(false)
        }
      }
    )
  }, 500)

  const getError = (capacity: number, subjectsCount: number) => {
    if (capacity < subjectsCount) return setError(intlAppointment('subjects.error'))
    return setError(undefined)
  }

  const addSubject = (subjectId: string) => {
    const subject = subjectOptions.find(s => s.value === subjectId)
    if (!subject) return
    setCurrentSubjects([...currentSubjects, subject])
    setSubjectOptions(subjectOptions.filter(s => s.value !== subjectId))
  }

  useEffect(() => {
    if (currentSubjects.length) {
      getError(currentCapacity, currentSubjects.length)
      formInstance.resetFields(['subjects'])
    }
  }, [currentSubjects.length, currentCapacity])

  useEffect(() => {
    if (error) formInstance.validateFields(['subjects'])
  }, [error])

  const onChangeCapacity = (value: number) => {
    setCurrentCapacity(value)
    getError(value, currentSubjects.length)
  }

  return (
    <>
      <DatacFormItem
        label={intlAppointment('capacity.label')}
        showAsterisk
        name="capacity"
        icon="users"
        validate={[validateRequired(intl('common.required')), isLessThan(intlAppointment('capacity.too_much'), 50)]}
      >
        <InputNumber
          size="large"
          placeholder={intlAppointment('capacity.placeholder')}
          precision={0}
          min={1}
          onChange={e => onChangeCapacity(e as number)}
        />
      </DatacFormItem>
      {currentSubjects.some(s => !s.sublabel) && (
        <DatacInformationMessage type="warning" message={intlAppointment('no_email.warning')} />
      )}
      <DatacFormItem
        label={intlAppointment('subjects.label')}
        name="subjects"
        error={error}
        icon="userPlus"
        validateStatus={error ? 'error' : undefined}
      >
        <DatacSelect
          showSearch
          options={subjectOptions}
          placeholder={intlAppointment('subjects.placeholder')}
          onSearch={onSearchSubjects}
          loading={isFetchingSubjectList}
          onChange={addSubject}
        />
      </DatacFormItem>
      <div className="appointment-edit-subjects">
        {currentSubjects.map(({ value, label, sublabel }) => (
          <div key={value} className="appointment-edit-subjects__subject">
            <div className="appointment-edit-subjects__subject__initials">{getInitials(label as string)}</div>
            <div className="appointment-edit-subjects__subject__name">
              <div className="appointment-edit-subjects__subject__name__label">{label}</div>
              <div className="appointment-edit-subjects__subject__name__email">{sublabel}</div>
            </div>
            <DatacIcon
              name="trash"
              onClick={() => setCurrentSubjects(currentSubjects.filter(s => s.value !== value))}
            />
          </div>
        ))}
      </div>
    </>
  )
}
