import './EventComponents.less'

import classNames from 'classnames'
import dayjs from 'dayjs'
import React from 'react'

import { Appointment, AppointmentColor } from '../../../../requests'
import { useCalendarStore } from '../../CalendarStore'
import { AppointmentPopup } from '../AppointmentPopup'

interface EventComponent {
  appointment: Appointment
  onClosePopup: (reload: boolean) => void
  showDate?: boolean
  isInSearch?: boolean
}

const getTimeInMinutes = (time: string) => {
  const timeArray = time.split(':').map(Number)
  return timeArray[0] * 60 + timeArray[1]
}

interface CapacityProps {
  subjectCount: number
  capacity: number
  isSimple?: boolean
}

const Capacity: React.VFC<CapacityProps> = ({ subjectCount, capacity, isSimple }) => (
  <div
    className={classNames(
      'calendar-components__capacity',
      capacity === subjectCount && !isSimple && 'calendar-components__capacity--full',
      isSimple && 'calendar-components__capacity--simple'
    )}
  >
    {subjectCount || 0} / {capacity || 0}
  </div>
)

const Dot: React.VFC<{ color: AppointmentColor }> = ({ color }) => (
  <div className={classNames('calendar-components__dot', `calendar-content__background--${color}`)} />
)

export const EventWeekComponent: React.VFC<EventComponent> = ({ appointment, onClosePopup }) => {
  const { startDate, endDate, startTime, endTime, subjects, capacity, title, color } = appointment
  const { userTimezone } = useCalendarStore()
  const duration = getTimeInMinutes(endTime) - getTimeInMinutes(startTime)
  const start = dayjs.tz(startDate, userTimezone).format('HH:mm')
  const end = dayjs.tz(endDate, userTimezone).format('HH:mm')

  return (
    <AppointmentPopup appointment={appointment} onClose={onClosePopup}>
      <div className={classNames('calendar-components__event', `calendar-content__background--${color}`)}>
        <div className="calendar-components__event__header">
          <div className="calendar-components__event__header__title">
            {start} - {end}
          </div>
          <Capacity subjectCount={subjects?.length} capacity={capacity} isSimple />
        </div>
        {duration >= 30 && <div className="calendar-components__event__content">{title}</div>}
      </div>
    </AppointmentPopup>
  )
}

export const EventAgendaComponent: React.VFC<EventComponent> = ({ appointment, onClosePopup, isInSearch }) => {
  const { startDate, endDate, title, color, subjects, capacity } = appointment
  const { userTimezone } = useCalendarStore()
  const isPast = endDate.isBefore(dayjs(), 'minute')
  const isToday = startDate.isSame(dayjs(), 'day')
  const start = dayjs.tz(startDate, userTimezone)
  const end = dayjs.tz(endDate, userTimezone)
  const componentInner = (
    <div
      className={classNames(
        'calendar-components__agenda',
        isPast && 'calendar-components--past',
        isInSearch && 'calendar-components__agenda--in-search'
      )}
    >
      {isInSearch ? (
        <div className="calendar-components__agenda__date">{start.format('D MMM YYYY')}</div>
      ) : (
        <div className="calendar-components__agenda__date">
          <div
            className={classNames(
              'calendar-components__agenda__date__day',
              isToday && 'calendar-components__agenda__date__day--today'
            )}
          >
            {start.format('D')}
          </div>
          <div className="calendar-components__agenda__date__month">{start.format('MMM')}</div>
          <div className="calendar-components__agenda__date__dot" />
          <div className="calendar-components__agenda__date__day-of-week">{start.format('ddd')}</div>
        </div>
      )}

      <Dot color={color} />
      <div className="calendar-components__agenda__time">
        {start.format('HH:mm')} - {end.format('HH:mm')}
      </div>
      {!isInSearch && <Capacity subjectCount={subjects?.length} capacity={capacity} />}
      <div className="calendar-components__agenda__title">{title}</div>
    </div>
  )
  return isInSearch ? (
    componentInner
  ) : (
    <AppointmentPopup appointment={appointment} onClose={onClosePopup}>
      {componentInner}
    </AppointmentPopup>
  )
}

export const EventMonthComponent: React.VFC<EventComponent> = ({ appointment, onClosePopup }) => {
  const { startDate, endDate, title, color, subjects, capacity } = appointment
  const { userTimezone } = useCalendarStore()
  const isPast = endDate.isBefore(dayjs(), 'minute')
  const start = dayjs.tz(startDate, userTimezone).format('HH:mm')
  return (
    <AppointmentPopup appointment={appointment} onClose={onClosePopup}>
      <div className={classNames('calendar-components__month', isPast && 'calendar-components--past')}>
        <Dot color={color} />
        <div>{start}</div>
        <Capacity subjectCount={subjects?.length} capacity={capacity} />
        <div className="calendar-components__month__title">{title}</div>
      </div>
    </AppointmentPopup>
  )
}
