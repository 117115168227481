import './CalendarDayMore.less'

import { Popover } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { Appointment } from '../../../../requests'
import { DatacIcon, DatacTitle } from '../../../common'
import { maxEventsInDay } from '../CalendarContent'
import { EventMonthComponent } from '../EventComponents'

interface Props {
  day: Date
  appointments: Appointment[]
  onClosePopup: (reload: boolean) => void
}

export const CalendarDayMore: React.VFC<Props> = ({ day, appointments, onClosePopup }) => {
  const intlCalendar = useScopedIntl('calendar')
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  const eventsInDayCount = appointments.filter(e => e.startDate.isSame(day, 'day')).length
  return (
    <Popover
      className="calendar-day-more__show-more"
      overlayClassName="calendar-day-more__overlay"
      open={isPopoverVisible}
      onOpenChange={setIsPopoverVisible}
      placement="bottomLeft"
      autoAdjustOverflow={false}
      content={
        <div className="calendar-day-more">
          <div className="calendar-day-more__header">
            <div className="calendar-day-more__header__date">
              <DatacTitle>{dayjs(day).format('MMMM DD')}</DatacTitle>
              {dayjs(day).format('dddd')}
            </div>
            <DatacIcon name="x" onClick={() => setIsPopoverVisible(false)} />
          </div>
          <div className="calendar-day-more__events">
            {appointments &&
              appointments
                .filter(a => a.startDate.isSame(day, 'day'))
                .map((a, i) => <EventMonthComponent key={i} appointment={a} onClosePopup={onClosePopup} />)}
          </div>
        </div>
      }
      trigger="click"
    >
      + {intlCalendar('more', { count: eventsInDayCount - maxEventsInDay + 1 })}
    </Popover>
  )
}
