import './CalendarSettingsPopup.less'

import { Switch } from 'antd'
import React, { useContext } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { UserConfigKey } from '../../../../requests'
import { UserContext } from '../../../auth'
import { useCalendarStore } from '../../CalendarStore'

export const CalendarSettingsPopup: React.VFC = () => {
  const { showWeekends, setShowWeekends, startsOnSunday, setStartsOnSunday, showEarlyHours, setShowEarlyHours } =
    useCalendarStore()
  const intlSettings = useScopedIntl('calendar.settings')
  const { user } = useContext(UserContext)
  const onShowWeekendsChange = (checked: boolean) => {
    setShowWeekends(checked)
    user.setConfigValue(UserConfigKey.CalendarShowWeekends, checked)

    if (!checked) {
      setStartsOnSunday(false)
      user.setConfigValue(UserConfigKey.CalendarStartsOnSunday, false)
    }
  }

  const onStartsOnSundayChange = (checked: boolean) => {
    setStartsOnSunday(checked)
    user.setConfigValue(UserConfigKey.CalendarStartsOnSunday, checked)
  }

  const onShowEarlyHoursChange = (checked: boolean) => {
    setShowEarlyHours(checked)
    user.setConfigValue(UserConfigKey.CalendarShowEarlyHours, checked)
  }

  return (
    <div className="calendar-settings-popup">
      <div className="calendar-settings-popup__header">{intlSettings('title')}</div>
      <div className="calendar-settings-popup__row">
        <Switch checked={showWeekends} onChange={onShowWeekendsChange} />
        <span>{intlSettings('show_weekends')}</span>
      </div>
      <div className="calendar-settings-popup__row">
        <Switch disabled={!showWeekends} checked={startsOnSunday} onChange={onStartsOnSundayChange} />
        <span>{intlSettings('start_week_on_sunday')}</span>
      </div>
      <div className="calendar-settings-popup__row">
        <Switch checked={showEarlyHours} onChange={onShowEarlyHoursChange} />
        <span>{intlSettings('show_early_hours')}</span>
      </div>
    </div>
  )
}
