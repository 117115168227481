import './AppointmentSearch.less'

import { RefSelectProps, Select } from 'antd'
import classNames from 'classnames'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { fetchAppointments as fetchAppointmentRequest } from '../../../../requests'
import { DatacMessage, DatacSelect } from '../../../common'
import { useCalendarStore } from '../../CalendarStore'
import { DatacEvent } from '../CalendarContent'
import { EventAgendaComponent } from '../EventComponents'

interface AppointmentPopupProps {
  setEvents: (events: DatacEvent[]) => void
}

export const AppointmentSearch: React.FC<AppointmentPopupProps> = ({ setEvents }) => {
  const intl = useScopedIntl('')
  const [currentEvents, setCurrentEvents] = useState<DatacEvent[]>(null)
  const { setCurrentView, forceEventDetails, currentView, currentDate } = useCalendarStore()
  const [isFetchingEvents, setIsFetchingEvents] = useState(false)
  const [currentSearch, setCurrentSearch] = useState('')
  const [shouldOpenAgendaAfterSearch, setShouldOpenAgendaAfterSearch] = useState(false)
  const [performedSearch, setPerformedSearch] = useState<string>(null)
  const searchRef = useRef<RefSelectProps>()

  useEffect(() => {
    clearPreviousSearch()
  }, [currentDate])

  useEffect(() => {
    if (currentView !== 'agenda') clearPreviousSearch()
  }, [currentView])

  useEffect(() => {
    if (shouldOpenAgendaAfterSearch && currentEvents?.length && currentSearch) {
      openEventsInAgenda(currentEvents)
    }
  }, [shouldOpenAgendaAfterSearch, currentEvents, currentSearch])

  const openEventsInAgenda = (events: DatacEvent[]) => {
    setEvents(events)
    setPerformedSearch(currentSearch)
    searchRef.current.blur()
    setCurrentView('agenda', false)
  }

  const fetchAppointment = useCallback(
    debounce((searchPhrase?: string) => {
      fetchAppointmentRequest(
        { search: searchPhrase },
        {
          onSuccess: ({ appointments }) => {
            setIsFetchingEvents(false)
            setCurrentEvents(
              appointments.map(appointment => ({
                id: appointment.id,
                start: appointment.startDate.toDate(),
                end: appointment.endDate.toDate(),
                title: appointment.title,
                appointment,
                eventAgendaComponent: <EventAgendaComponent appointment={appointment} onClosePopup={onClosePopup} />,
                eventWeekComponent: <></>,
                eventMonthComponent: <></>
              }))
            )
          },
          onRequestError: code => DatacMessage.genericError(intl, code)
        }
      )
    }, 1000),
    []
  )

  const onSearchEvents = (searchPhrase?: string) => {
    setCurrentEvents(null)

    if (!searchPhrase) {
      setCurrentSearch('')
      return
    }

    setIsFetchingEvents(true)
    setCurrentSearch(searchPhrase)
    fetchAppointment(searchPhrase)
  }

  const onSelectEvent = (id: number) => {
    const event = currentEvents.find(e => e.id === id)
    if (event) {
      openEventsInAgenda(currentEvents)
      setPerformedSearch(currentSearch)
      forceEventDetails(event)
    }
  }

  const ondKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setShouldOpenAgendaAfterSearch(false)

    if (e.key === 'Enter' && currentSearch.trim()) {
      if (isFetchingEvents) setShouldOpenAgendaAfterSearch(true)
      else openEventsInAgenda(currentEvents)
    }
  }

  const onClosePopup = (reload: boolean) => {
    if (reload) {
      setCurrentEvents(null)
      setShouldOpenAgendaAfterSearch(true)
      onSearchEvents(currentSearch)
    }
  }

  const clearPreviousSearch = () => {
    setCurrentEvents(null)
    setPerformedSearch(null)
    setCurrentSearch('')
  }

  return (
    <DatacSelect
      selectRef={searchRef}
      className="calendar-appointment-search"
      placeholder={intl('common.search')}
      showSearch
      loading={isFetchingEvents}
      onSearch={onSearchEvents}
      onSelect={onSelectEvent}
      onInputKeyDown={ondKeyPressed}
      value={currentSearch || performedSearch}
      dropDownClassName={classNames('calendar-appointment-search__dropdown', {
        'calendar-appointment-search__dropdown--disabled': currentEvents === null && !isFetchingEvents
      })}
      defaultActiveFirstOption={false}
    >
      {currentEvents &&
        currentEvents.map(e => (
          <Select.Option value={e.id} key={e.id}>
            <EventAgendaComponent appointment={e.appointment} onClosePopup={onClosePopup} isInSearch />
          </Select.Option>
        ))}
    </DatacSelect>
  )
}
