import './AppointmentPopup.less'

import { Popover } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { AclAction, AclFeature, Appointment, deleteAppointment } from '../../../../requests'
import { routes } from '../../../../routes'
import { getInitials, localeFromPath } from '../../../../utils'
import { UserContext } from '../../../auth'
import { DatacIcon, DatacMessage, DatacPopconfirm, DatacTitle, DatacToggleExpand } from '../../../common'
import { useCalendarStore } from '../../CalendarStore'

interface AppointmentPopupProps {
  appointment: Appointment
  onClose?: (reload?: boolean) => void
  children?: React.ReactNode
}

export const AppointmentPopup: React.FC<AppointmentPopupProps> = ({ appointment, onClose, children }) => {
  const { forcedEventDetailsId, clearForcedEventDetailsId, currentView, setAppointmentToEdit, userTimezone } =
    useCalendarStore()
  const intl = useScopedIntl('')
  const intlSubjects = useScopedIntl('calendar.appointment.subjects')
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const [isShowingSubjects, setIsShowingSubjects] = useState(false)
  const { user } = useContext(UserContext)

  const onDelete = () => {
    deleteAppointment(
      { id: appointment.id },
      {
        onSuccess: () => {
          onClose(true)
          onVisibleChange(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const onVisibleChange = (visible: boolean) => {
    setIsPopoverVisible(visible)
    if (!visible) clearForcedEventDetailsId()
  }

  const availableSubjects = appointment.capacity - appointment.subjects?.length

  const start = dayjs.tz(appointment.startDate, userTimezone)
  const end = dayjs.tz(appointment.endDate, userTimezone)
  const localizedDate = start.locale(localeFromPath())

  return (
    <Popover
      open={isPopoverVisible || forcedEventDetailsId === appointment.id}
      onOpenChange={onVisibleChange}
      placement={currentView === 'agenda' ? 'bottomLeft' : 'right'}
      trigger="click"
      content={
        <div className="calendar-appointment-popup">
          <div className="calendar-appointment-popup__header">
            <div
              className={classNames(
                'appointment-edit__modal__color-picker__color',
                `calendar-content__background--${appointment.color}`
              )}
            />
            <div className="calendar-appointment-popup__header__actions">
              {user.canDo(AclFeature.Calendar)(AclAction.Edit) && (
                <DatacIcon name="edit" onClick={() => setAppointmentToEdit(appointment)} />
              )}
              {user.canDo(AclFeature.Calendar)(AclAction.Delete) && (
                <DatacPopconfirm onConfirm={onDelete} okText={intl('common.delete')}>
                  <DatacIcon name="trash" />
                </DatacPopconfirm>
              )}
            </div>
            <DatacIcon name="x" onClick={() => onVisibleChange(false)} />
          </div>
          <DatacTitle>{appointment.title}</DatacTitle>
          <div className="calendar-appointment-popup__row">
            <DatacIcon name="calendar" />
            <div>
              {localizedDate.format('D MMM YYYY')}
              <span> ({localizedDate.format('dddd,')})</span>
            </div>
          </div>
          <div className="calendar-appointment-popup__row">
            <DatacIcon name="clock" />
            <div>
              {start.format('HH:mm')} - {end.format('HH:mm')}
            </div>
          </div>
          {appointment.center?.name && (
            <div className="calendar-appointment-popup__row">
              <DatacIcon name="mapPin" />
              <div>
                {appointment.center.name}
                <span> ({appointment.center.abbreviation})</span>
              </div>
            </div>
          )}
          {appointment.study?.uuid && (
            <div className="calendar-appointment-popup__row">
              <DatacIcon name="clipboard" />
              <div
                className="calendar-appointment-popup__row__link"
                onClick={() => navigate(routes.studyDashboard(appointment.study.uuid))}
              >
                {appointment.study.reference} - {appointment.study.name}
              </div>
            </div>
          )}
          {appointment.capacity && (
            <div className="calendar-appointment-popup__row">
              <DatacIcon name="users" />
              <div className="calendar-appointment-popup__subjects">
                <div
                  className="calendar-appointment-popup__subjects__toggle"
                  onClick={() => setIsShowingSubjects(!isShowingSubjects)}
                >
                  {`${appointment.subjects.length}/${appointment.capacity} ${intlSubjects('label')}`}
                  <DatacToggleExpand type="blue" isCollapsed={isShowingSubjects} onToggle={() => null} />
                </div>
                {isShowingSubjects && (
                  <div className="calendar-appointment-popup__subjects__list">
                    {appointment.subjects.map(s => (
                      <div key={s.id} className="calendar-appointment-popup__subjects__list__subject">
                        <div className="calendar-appointment-popup__subjects__list__subject__initials">
                          {getInitials(s.fullName)}
                        </div>
                        <div className="calendar-appointment-popup__subjects__list__subject__name">
                          <div className="calendar-appointment-popup__subjects__list__subject__name__label">
                            {s.fullName || s.id}
                          </div>
                          <div className="calendar-appointment-popup__subjects__list__subject__name__email">
                            {s.email}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {availableSubjects > 0 && (
                <div className="calendar-appointment-popup__available">
                  <div className="calendar-appointment-popup__available__count">
                    {intlSubjects('available', { count: availableSubjects })}
                  </div>
                  <div
                    className="calendar-appointment-popup__available__add"
                    onClick={() => setAppointmentToEdit(appointment)}
                  >
                    <DatacIcon name="plus" raw /> {intlSubjects('add')}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      }
    >
      {children}
    </Popover>
  )
}
