import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../../hooks'
import { SorterOrder, fetchStudies, fetchStudy } from '../../../../../requests'
import { DatacFormItem, DatacMessage, DatacOption, DatacSelect } from '../../../../common'
import { useCalendarStore } from '../../../CalendarStore'

const studiesListLimit = 50

interface AppointmentEditStudyProps {
  studyId: string
  setStudyId: (studyId: string) => void
}

export const AppointmentEditStudy: React.VFC<AppointmentEditStudyProps> = ({ setStudyId, studyId }) => {
  const intlStudy = useScopedIntl('calendar.appointment.study')
  const intl = useScopedIntl('')
  const [studiesOptions, setStudiesOptions] = useState<DatacOption[]>([])
  const [isFetchingStudyList, setIsFetchingStudyList] = useState(false)
  const { appointmentToEdit } = useCalendarStore()

  useEffect(() => {
    if (appointmentToEdit?.study?.uuid) {
      fetchStudy(
        { studyId: appointmentToEdit.study?.uuid },
        {
          onSuccess: study => {
            setStudiesOptions([
              ...studiesOptions.filter(s => s.value !== study.id),
              {
                label: study.reference,
                sublabel: study.name,
                value: study.id
              }
            ])
          }
        }
      )
    }
  }, [appointmentToEdit])

  const onSearchStudies = debounce((searchPhrase?: string) => {
    setIsFetchingStudyList(true)
    fetchStudies(
      {
        options: {
          sorter: { order: SorterOrder.Descend, field: 'dateAdded' },
          limit: studiesListLimit,
          offset: 0,
          search: searchPhrase
        }
      },
      {
        onSuccess: newStudies => {
          setStudiesOptions([
            ...studiesOptions.filter(s => s.value === studyId),
            ...newStudies
              .filter(s => s.id !== studyId)
              .map(s => ({ label: s.reference, sublabel: s.name, value: s.id }))
          ])
          setIsFetchingStudyList(false)
        },
        onRequestError: code => {
          DatacMessage.genericError(intl, code)
          setIsFetchingStudyList(false)
        }
      }
    )
  }, 1000)

  return (
    <DatacFormItem label={intlStudy('label')} name="studyId" icon="clipboard">
      <DatacSelect
        options={studiesOptions}
        size="large"
        placeholder={intlStudy('placeholder')}
        onSearch={onSearchStudies}
        showSearch
        loading={isFetchingStudyList}
        onChange={setStudyId}
        allowBlank
      />
    </DatacFormItem>
  )
}
