import './CalendarLayout.less'

import { Button, Calendar } from 'antd'
import classNames from 'classnames'
import { format } from 'date-fns'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en-gb'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'
import 'dayjs/locale/ja'
import 'dayjs/locale/pl'
import 'dayjs/locale/pt'
import 'dayjs/locale/zh-cn'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import { AclFeature, AutomaticTimezone, UserConfigKey } from '../../../requests'
import { routes } from '../../../routes'
import { localeFromPath } from '../../../utils'
import { RedirectNoAccessWrapper } from '../../RedirectNoAccessWrapper'
import { UserContext } from '../../auth'
import { DatacIcon, DatacMessage, DatacTitle } from '../../common'
import { useCalendarStore } from '../CalendarStore'

export const CalendarLayout: React.FC = ({ children }) => {
  const intl = useScopedIntl('')
  const intlCalendar = useScopedIntl('calendar')
  const [isMinimized, setIsMinimized] = useState(false)
  const { user } = useContext(UserContext)
  const {
    currentDate,
    setCurrentDate,
    goToPrevious,
    goToNext,
    showWeekends,
    setShowWeekends,
    startsOnSunday,
    setStartsOnSunday,
    showEarlyHours,
    setShowEarlyHours,
    setUserTimezone
  } = useCalendarStore()

  useEffect(() => {
    if (user.shouldShowNextReleaseInfo()) {
      DatacMessage.nextReleaseInfo(intl, user.getNextRelease(), user.closeNextReleaseNotes, user.language)
    }
    dayjs.locale(localeFromPath())
    const timezone = user.timezone === AutomaticTimezone ? dayjs.tz.guess() : user.timezone
    dayjs.tz.setDefault(timezone)
    setUserTimezone(timezone, currentDate)

    setShowWeekends(user.getConfigValue(UserConfigKey.CalendarShowWeekends, showWeekends))
    setStartsOnSunday(user.getConfigValue(UserConfigKey.CalendarStartsOnSunday, startsOnSunday))
    setShowEarlyHours(user.getConfigValue(UserConfigKey.CalendarShowEarlyHours, showEarlyHours))
  }, [user])

  useEffect(() => {
    dayjs.updateLocale(localeFromPath(), { weekStart: startsOnSunday ? 0 : 1 })
  }, [startsOnSunday])

  return (
    <RedirectNoAccessWrapper
      hasNoAccess={!user.isCalendarEnabled || !user.canAccess(AclFeature.Calendar)}
      path="calendar"
    >
      <div className={classNames('calendar-layout', isMinimized && 'calendar-layout--minimized')}>
        <div className="calendar-layout__menu">
          {!isMinimized && (
            <>
              <DatacTitle type="h1" className="calendar-layout__menu-title">
                {intlCalendar('title')}
              </DatacTitle>
              <div className="calendar-layout__menu__date">
                {intlCalendar('today')}, {format(new Date(), 'dd MMM yyyy')}
              </div>
            </>
          )}

          <DatacIcon
            className="calendar-layout__menu__toggle-minimize"
            size="small"
            name={isMinimized ? 'chevronRight' : 'chevronLeft'}
            type="white"
            onClick={() => setIsMinimized(!isMinimized)}
          />
          {!isMinimized && (
            <div className="calendar-layout__menu__calendar">
              <div className="calendar-layout__menu__calendar__header">
                <button type="button" onClick={() => goToPrevious()}>
                  <DatacIcon name="chevronLeft" />
                </button>
                <span>{format(currentDate, 'MMM yyyy')}</span>
                <button type="button" onClick={() => goToNext()}>
                  <DatacIcon name="chevronRight" />
                </button>
              </div>
              <Calendar value={dayjs(currentDate)} onSelect={(v: Dayjs) => setCurrentDate(v.toDate())} />
            </div>
          )}
          {!isMinimized && (
            <div className="calendar-layout__menu__buttons">
              <Button type="default" onClick={() => navigate(routes.bookings)}>
                <DatacIcon name="user" raw />
                {intlCalendar('bookings.title')}
              </Button>
            </div>
          )}
        </div>
        <main className="calendar-layout__content">{children}</main>
      </div>
    </RedirectNoAccessWrapper>
  )
}
